<template>
    <v-card min-height="125" :loading="$store.state.market.loading" :disabled="$store.state.market.loading">
        <v-card-text class="d-flex pt-5">
            <v-avatar size="80">
                <v-img :src="icon"></v-img>
            </v-avatar>
            <div class="d-flex justify-center" style="width: 100%" >
                <div class="d-flex flex-column justify-space-between">
                    <span class="text-xl text--primary">{{ title }}</span>
                    <v-btn
                        width="170"
                        class="me-n3 mt-n1"
                        color="primary"
                        :to="{name: 'store-products', params: {slug: slug}}"
                    >
                        {{ 'Go to storefront' }}
                    </v-btn>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import store from "@/store";
import { computed } from "@vue/composition-api";

export default {
    name: "SellerStore",
    setup() {
        store.dispatch('market/getMarket')
        return {
            icon: computed(() => store.state.market.market?.icon || require('@/assets/images/avatars/human_coffe.png')),
            title: computed(() => store.state.market.market?.title || 'Store title'),
            slug: computed(() => store.state.market.market?.slug),
        }
    }
}
</script>