<template>
    <v-card min-height="150" :loading="$store.state.market.loading" :disabled="$store.state.market.loading" class="d-flex justify-space-between balance" style="padding: 0, 45px, 0, 0">
        <v-card-title class="d-flex flex-column align-center justify-center">
            <div class="d-flex flex-column">
                <span class="d-flex flex-inline align-baseline" style="white-space: nowrap">
                    <span>{{ $t('Balance') }}</span>
                    <h2 class="ml-2">${{ balance }}</h2>
                </span>
                <span class="mt-4 text-xs d-flex align-baseline" style="white-space: nowrap">
                    <span class="mr-2">{{ 'Pending payouts' }}</span>
                    <v-tooltip
                        v-if="$store.state.user.userResource.seller_payout_method_invalid"
                        :max-width="300"
                        bottom
                        color="secondary"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                style="align-self: center"
                            >
                                <v-icon size="18" color="warning" class="mx-2">{{ icons.mdiAlert }}</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ $t('The payout method you\'ve added is not valid. Please check your payout details or add a valid payout method.') }}
                        </span>
                    </v-tooltip>
                    <v-tooltip
                        v-if="!$store.state.user.userResource.seller_payout_method"
                        :max-width="300"
                        bottom
                        color="secondary"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                style="align-self: center"
                            >
                                <v-icon size="18" color="warning" class="mx-2">{{ icons.mdiAlert }}</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ $t('You have not added a payment method to your store. To receive payouts, please add at least one valid payment method.') }}
                        </span>
                    </v-tooltip>
                    <span class="text--primary text-xl ml-2">{{ statisticPayouts }}</span>
                    <v-btn small icon class="me-n3 mt-n1 ml-2" :to="{ name: 'store-transactions', params: {tab: 'payouts'}}">
                        <!-- Arrow -->
                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" />
                        </svg>
                    </v-btn>
                </span>
            </div>
        </v-card-title>
        <div style="align-self: end">
            <v-img :width="90" :src="avatar"></v-img>
        </div>
    </v-card>
</template>

<script>
import {
    ref,
    computed
} from "@vue/composition-api"
import store from "@/store"
import axios from "@axios";
import { mdiReplay, mdiAlert } from '@mdi/js';

export default {
    name: "SellerBalance",
    setup() {
        const sellersBalance = ref('-')
        const show = ref(false)
        const getSellersBalance = () => {
            axios.get('admin/dashboard/sellers-and-users-balance').then(res => {
                sellersBalance.value = res.data.sellers
            }).catch(e => console.log(e))
        }
        store.dispatch('payouts/getSellerPayoutsDashData')
        return {
            show,
            balance: computed(() => store.state.market.balance || '-'),
            sellersBalance,
            avatar: require('@/assets/images/avatars/human_coffe.png'),
            statisticPayouts: computed(() => '$' + store.state.payouts.statisticPayouts.toString()),
            icons: {
                mdiReplay,
                mdiAlert
            }
        }
    }
}
</script>
