<template>
    <v-card min-height="299" class="pb-0" :loading="$store.state.order.loading" :disabled="$store.state.order.loading">
        <v-card-title class="align-start">
            <v-row>
                <v-col cols="12" md="8" class="d-flex align-center pb-0">
                    <span class="text--primary">{{ $t("Sales") }}</span>
                </v-col>
                <v-col cols="12" md="4" class="pb-0">
                    <div class="d-flex justify-space-between align-center">
                        <div class="d-flex flex-column">
                            <span class="text--primary" style="font-weight: 600">${{ sales.today || '-' }}</span>
                            <span class="text-fs-14 mt-1">{{ 'Today' }}</span>
                        </div>
                        <v-btn small icon class="me-n3 mt-n1" :to="to">
                            <!-- Arrow -->
                            <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" />
                            </svg>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="8" class="pb-0" style="height: 235px">
                    <vue-apex-charts
                        v-if="!loading"
                        :options="chartOptions"
                        :series="chartData"
                        height="210"
                    />
                </v-col>
                <v-col cols="12" md="4" class="pb-0">
                    <v-list-item style="height: 100%">
                        <v-list-item-content>
                            <div class="d-flex flex-column">
                                <div class="d-flex align-center">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_2876_127949)">
                                            <rect x="10" y="8" width="40" height="40" rx="20" fill="#EAF5EA" />
                                            <path d="M29.8003 26.9C27.5303 26.31 26.8003 25.7 26.8003 24.75C26.8003 23.66 27.8103 22.9 29.5003 22.9C31.2803 22.9 31.9403 23.75 32.0003 25H34.2103C34.1403 23.28 33.0903 21.7 31.0003 21.19V19H28.0003V21.16C26.0603 21.58 24.5003 22.84 24.5003 24.77C24.5003 27.08 26.4103 28.23 29.2003 28.9C31.7003 29.5 32.2003 30.38 32.2003 31.31C32.2003 32 31.7103 33.1 29.5003 33.1C27.4403 33.1 26.6303 32.18 26.5203 31H24.3203C24.4403 33.19 26.0803 34.42 28.0003 34.83V37H31.0003V34.85C32.9503 34.48 34.5003 33.35 34.5003 31.3C34.5003 28.46 32.0703 27.49 29.8003 26.9Z" fill="#56CA00" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_2876_127949" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="2" />
                                                <feGaussianBlur stdDeviation="5" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2876_127949" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2876_127949" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                    <div class="d-flex flex-column">
                                        <span style="font-size: 16px; font-weight: 600">${{ sales.lastWeek || '-' }}</span>
                                        <span style="font-size: 12px" class="mt-2">{{ $t("last 7 days")}}</span>
                                    </div>
                                </div>
                                <div class="d-flex align-center">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_2876_127949)">
                                            <rect x="10" y="8" width="40" height="40" rx="20" fill="#FFF9EB" />
                                            <path d="M29.8003 26.9C27.5303 26.31 26.8003 25.7 26.8003 24.75C26.8003 23.66 27.8103 22.9 29.5003 22.9C31.2803 22.9 31.9403 23.75 32.0003 25H34.2103C34.1403 23.28 33.0903 21.7 31.0003 21.19V19H28.0003V21.16C26.0603 21.58 24.5003 22.84 24.5003 24.77C24.5003 27.08 26.4103 28.23 29.2003 28.9C31.7003 29.5 32.2003 30.38 32.2003 31.31C32.2003 32 31.7103 33.1 29.5003 33.1C27.4403 33.1 26.6303 32.18 26.5203 31H24.3203C24.4403 33.19 26.0803 34.42 28.0003 34.83V37H31.0003V34.85C32.9503 34.48 34.5003 33.35 34.5003 31.3C34.5003 28.46 32.0703 27.49 29.8003 26.9Z" fill="#E09E00" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_2876_127949" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="2" />
                                                <feGaussianBlur stdDeviation="5" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2876_127949" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2876_127949" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                    <div class="d-flex flex-column">
                                        <span style="font-size: 16px; font-weight: 600">${{ sales.lastMonth || '-' }}</span>
                                        <span style="font-size: 12px" class="mt-2">{{ $t("last 30 days")}}</span>
                                    </div>
                                </div>
                                <div class="d-flex align-center">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_2876_127949)">
                                            <rect x="10" y="8" width="40" height="40" rx="20" fill="#EFFAFF" />
                                            <path d="M29.8003 26.9C27.5303 26.31 26.8003 25.7 26.8003 24.75C26.8003 23.66 27.8103 22.9 29.5003 22.9C31.2803 22.9 31.9403 23.75 32.0003 25H34.2103C34.1403 23.28 33.0903 21.7 31.0003 21.19V19H28.0003V21.16C26.0603 21.58 24.5003 22.84 24.5003 24.77C24.5003 27.08 26.4103 28.23 29.2003 28.9C31.7003 29.5 32.2003 30.38 32.2003 31.31C32.2003 32 31.7103 33.1 29.5003 33.1C27.4403 33.1 26.6303 32.18 26.5203 31H24.3203C24.4403 33.19 26.0803 34.42 28.0003 34.83V37H31.0003V34.85C32.9503 34.48 34.5003 33.35 34.5003 31.3C34.5003 28.46 32.0703 27.49 29.8003 26.9Z" fill="#139CE0" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_2876_127949" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="2" />
                                                <feGaussianBlur stdDeviation="5" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2876_127949" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2876_127949" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                    <div class="d-flex flex-column">
                                        <span style="font-size: 16px; font-weight: 600">${{ sales.total || '-' }}</span>
                                        <span style="font-size: 12px" class="mt-2">{{ $t("Total Sales")}}</span>
                                    </div>
                                </div>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { computed, onMounted, ref } from "@vue/composition-api";
import VueApexCharts from 'vue-apexcharts'
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { kFormatter } from '@core/utils/filter'
import { getVuetify } from '@core/utils'
import store from '@/store'

export default {
    name: 'SellerSales',

    components: {
        VueApexCharts,
    },
    setup() {
        const $vuetify = getVuetify()
        const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'
        const chartData = ref([{
            name: 'Sales',
            data: [0, 0, 0, 0, 0, 0, 0]
        }])
        const loading = ref(true)
        const chartOptions = {
            colors: [
                $vuetify.theme.currentTheme.primary
            ],
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
                offsetX: -15,
            },
            plotOptions: {
                bar: {
                    columnWidth: '30%',
                    // distributed: true,
                    borderRadius: 8,
                    startingShape: 'rounded',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            xaxis: {
                categories: [],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                // tickPlacement: 'on',
                labels: {
                    show: true,
                    style: {
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                // show: true,
                tickAmount: 4,
                labels: {
                    offsetY: 3,
                    formatter: value => `$${kFormatter(value, 0)}`,
                },
            },
            stroke: {
                width: [2, 2],
            },
            grid: {
                strokeDashArray: 12,
                padding: {
                    right: 0,
                },
            },
        }
        onMounted(() => {
            loading.value = true
            store.dispatch('order/getSellerSalesDash').then(() => {
                chartOptions.xaxis.categories = store.state.order.sales.chartTime
                chartData.value[0].data = store.state.order.sales.chartData
                loading.value = false
            })
        })
        return {
            sales: computed(() => store.state.order.sales),
            chartData,
            chartOptions,
            loading,
            icons: {
                mdiDotsVertical,
                mdiTrendingUp,
                mdiCurrencyUsd
            },
            to: computed(() => {
                return {
                    name: 'store-transactions',
                    params: {
                        tab: 'all'
                    }
                }
            }),
        }
    }
}
</script>