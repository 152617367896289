<template>
    <v-card :loading="$store.state.market.loading" :disabled="$store.state.market.loading">
        <v-card-title class="d-flex justify-space-between" style="width: 100%">
            <span class="text--primary">{{ $t("Refund Requests") }}</span>
            <v-btn small icon class="me-n3 mt-n1" :to="{ name: 'seller-refunds-list', params: {tab: 'refund-requests'}}">
                <!-- Arrow -->
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" />
                </svg>
            </v-btn>
        </v-card-title>
        <v-data-table
            :headers="refundHeaders"
            :items="refunds"
            :loading="loading"
            :mobile-breakpoint="0"
            hide-default-footer
        >
            <template #no-data>
                <div class="mt-8 mb-8">
                    <EmptyTableIcon />
                    <p class="empty-title">{{ $t('Your list is empty.')}}</p>
                </div>
            </template>
            <template #[`item.dispute_id`]="{item}">
                <router-link :to="{name: 'seller-order', params: {id: item.id}}">{{ item.dispute_id }}</router-link>
            </template>
            <template #[`item.dispute_date`]="{item}">
                <span>{{ item.dispute_date.slice(0, 10) }}</span>
            </template>
            <template #[`item.status`]="{item}">
                <div>
                    <v-chip
                        v-if="status[item.status]"
                        small
                        :color="statusColor[status[item.status]]"
                        class="font-weight-semibold py-3"
                        :class="{'mt-2': isTimer(item) && item.status == 2}"
                    >
                        {{ status[item.status] }}
                    </v-chip>
                    <count-down-timer
                        v-if="isTimer(item) && item.status == 2"
                        :end="item.order_history[0].date_end"
                        color="error"
                        :tooltip="'You have ' + item.refund_expired_period + ' days to make a decision. Otherwise the order will be refunded automatically.'"
                        class="mt-1"
                    />
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { ref } from "@vue/composition-api"
import axios from "@axios"
import CountDownTimer from "@/views/cabinet/order/order-list/CountDownTimer";
import EmptyTableIcon from "@/views/components/icon/EmptyTableIcon";

export default {
    name: "SellerRefund",
    components: {
        CountDownTimer,
        EmptyTableIcon
    },
    setup() {
        const refunds = ref([])
        const loading = ref(false)
        const getRefundsFromApi = () => {
            loading.value = true
            axios.get('dashboard/refund-requests', {
                params: {
                    tab: 'refund-requests'
                }
            }).then(res => {
                loading.value = false
                refunds.value = res.data.data
            }).catch(e => console.log(e))
        }
        getRefundsFromApi()
        const isTimer = (item) => Math.trunc((new Date()).getTime() / 1000) < Math.trunc(Date.parse(item.order_history[0].date_end) / 1000)
        return {
            isTimer,
            refunds,
            loading,
            refundHeaders: [
                { text: 'DISPUTE ID', value: 'dispute_id', sortable: false },
                { text: 'DATE', value: 'dispute_date', sortable: false, align: 'center' },
                { text: 'STATUS', value: 'status', sortable: false, align: 'center' },
            ],
            status: {
                2: 'Refund Requested',
            },
            statusColor: {
                'Refund Requested': 'error',
            }
        }
    }
}
</script>