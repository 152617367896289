<template>
<!-- Seller dashboard -->
<div class="dashboard-container">
    <account-role-title role="Store" />
    <v-row>
        <v-col cols="12" md="4" order="1">
            <SellerBalance class="mb-6" />
            <SellerStore />
        </v-col>
        <v-col cols="12" md="8" order="2">
            <SellerSales />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="8" order="1">
            <SellerInventory />
        </v-col>
        <v-col cols="12" md="4" order="2">
            <div class="d-flex">
                <Orders class="mr-6" />
                <Refunds />
            </div>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="8" order="1">
            <SellerTransaction />
        </v-col>
        <v-col cols="12" md="4" order="2">
            <SellerRefund />
        </v-col>
    </v-row>
</div>
</template>

<script>
import SellerSales from "@/views/cabinet/dashboard/blocks/SellerSales";
import SellerBalance from "@/views/cabinet/dashboard/blocks/SellerBalance";
import SellerRefund from "@/views/cabinet/dashboard/blocks/SellerRefund";
import SellerTransaction from "@/views/cabinet/dashboard/blocks/SellerTransaction";
import SellerInventory from "@/views/cabinet/dashboard/blocks/SellerInventory";
import SellerStore from "@/views/cabinet/dashboard/blocks/SellerStore";
import Orders from "@/views/cabinet/dashboard/blocks/Orders";
import Refunds from "@/views/cabinet/dashboard/blocks/Refunds";

export default {
    name: "Dashboard",
    components: {
        SellerRefund,
        SellerTransaction,
        SellerInventory,
        SellerStore,
        SellerSales,
        SellerBalance,
        Orders,
        Refunds,
    }
}
</script>
