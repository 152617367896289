<template>
    <v-card :loading="$store.state.market.loading" :disabled="$store.state.market.loading">
        <v-card-title class="d-flex justify-space-between" style="height: fit-content;width: 100%">
            <span class="text--primary">{{ $t("Transaction") }}</span>
            <v-btn small icon class="me-n3 mt-n1" :to="{ name: 'store-transactions', params: {tab: 'all'}}">
                <!-- Arrow -->
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" />
                </svg>
            </v-btn>
        </v-card-title>
        <v-data-table
            :headers="transactionHeaders"
            :items="transactions"
            :loading="loading"
            :mobile-breakpoint="0"
            hide-default-footer
        >
            <template #no-data>
                <div class="mt-8 mb-8">
                    <EmptyTableIcon />
                    <p class="empty-title">{{ $t('Your list is empty.')}}</p>
                </div>
            </template>
            <template #[`item.type`]="{item}">
                <v-chip
                    small
                    :color="typeColor[item.type]"
                    :class="`${typeColor[item.type]}--text`"
                    class="v-chip-light-bg"
                >
                    {{ item.type }}
                </v-chip>
            </template>
            <template #[`item.obj_id`]="{item}">
                <span v-if="item.type == 'Payout'">{{ $t("Payout") }}</span>
                <span v-else-if="item.type == 'Order'">
                    {{ $t('Order id ') }}
                </span>
                <span v-else-if="item.type == 'Refund'">
                    {{ $t('Refund id ') }}
                </span>
                <router-link v-if="item.type == 'Order' || item.type == 'Refund'" style="text-decoration: none" :to="{name: 'seller-order', params: {id: item.obj_id}}">
                    {{ item.obj_id }}
                </router-link>
            </template>
            <template #[`item.debit`]="{item}">
                {{ item.debit?`$${Number(item.debit)}`:'-' }}
            </template>
            <template #[`item.credit`]="{item}">
                {{ item.credit?`$${Number(item.credit)}`:'-' }}
            </template>
            <template #[`item.balance`]="{item}">
                {{ `$${Number(item.balance)}` }}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { ref } from "@vue/composition-api"
import axios from "@axios"
import EmptyTableIcon from "@/views/components/icon/EmptyTableIcon";

export default {
    name: "SellerTransaction",
    components: {
        EmptyTableIcon
    },
    setup() {
        const transactions = ref([])
        const loading = ref(false)
        const getTransactionsFromApi = () => {
            loading.value = true
            axios.get('dashboard/transactions').then(res => {
                loading.value = false
                transactions.value = res.data.data
            }).catch(e => console.log(e))
        }
        getTransactionsFromApi()
        return {
            transactions,
            loading,
            typeColor: {
                Payout: 'success',
                Order: 'primary',
                Refund: 'error'
            },
            transactionHeaders: [
                { text: 'DATE', value: 'post_date', sortable: false },
                { text: 'TYPE', value: 'type', sortable: false },
                { text: 'DESCIPTION', value: 'obj_id', sortable: false },
                { text: 'RECEIVED', value: 'credit', sortable: false },
                { text: 'SPEND', value: 'debit', sortable: false },
                { text: 'BALANCE', value: 'balance', sortable: false },
            ]
        }
    }
}
</script>